import queryString from "query-string";
import React from "react";
import * as yup from "yup";
import ChangePassword from "../../components/user-management/ChangePassword";

type PropsType = {
  location: { search: string };
};

type QueryStringType = {
  token: string;
};

export default ({ location }: PropsType) => {
  const InputSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Bitte geben Sie Ihre Email an"),
  });

  const loc = queryString.parse(location.search) as QueryStringType;

  return (
    <div>
      Bitte vergeben Sie ein neues Passwort.
      <div style={{ maxWidth: "600px", margin: "auto", marginTop: "1em" }}>
        <ChangePassword token={loc.token || ""} expectOldPassword={false} />
      </div>
    </div>
  );
};
